<template>
    <div class="login">
        <div class="login-content">
            <img class="login-image" src="@/assets/images/login.png" />
            <div class="main">
                <div class="login-box">
                    <h1>欢迎登录</h1>
                    <h2>WELCOME LANDING</h2>

                    <div>
                        <div class="login-input" :class="{ 'login-input-active': activeInput === 1 }">
                            <img src="@/assets/images/login-user.png" alt class="input-icon" />
                            <input
                                v-model="form.username"
                                placeholder="请输入账号"
                                @focus="activeInput = 1"
                                @blur="activeInput = 0"
                            />
                        </div>
                        <div class="login-input" :class="{ 'login-input-active': activeInput === 2 }">
                            <img src="@/assets/images/login-password.png" alt class="input-icon" />

                            <input
                                type="password"
                                v-model="form.password"
                                placeholder="请输入密码"
                                @focus="activeInput = 2"
                                @blur="activeInput = 0"
                            />
                        </div>
                        <span style="font-size: 14px;color: red;">密码必须包含大小写字母、数字、特殊符号</span>
                        <a-button :loading="loading" @click="login" class="login-button">登录</a-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="login-footer">
            <p>@2022 广州市广农数链信息科技有限公司 增值电信业务经营去可证粤B2-20182568</p>
            <p>粤公网安备 44010602006049号 粤ICP备17041355号-6</p>
        </div>
    </div>
</template>

<script>
import { login } from '@/api/admin'
import { setUserInfo } from '@/utils/auth'
export default {
  data () {
    return {
      form: {
        username: '',
        password: ''
      },
      loading: false,
      activeInput: 0
    }
  },
  methods: {
    login () {
      this.loading = true
      login(this.form).then(res => {
        this.$message.success('登录成功')
        this.loading = false
        setUserInfo(res)
        this.$router.replace({
          name: 'Admin'
        })
      }).catch(err => {
        this.$message.error(err.msg)
        this.form = {
          username: '',
          password: ''
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f3f4f6;
    .login-content {
        width: 100%;
        max-width: 1000px;
        height: 570px;
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15),
            0px 4px 40px 0px rgba(0, 0, 0, 0.15);
        background: #ffffff;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .login-image {
            width: 302px;
            height: 100%;
            background: #55b98a;
        }

        .main {
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .login-box {
                width: 380px;

                h1 {
                    font-size: 32px;
                }

                h2 {
                    color: #bebebe;
                    font-size: 16px;
                    font-weight: normal;
                    margin-bottom: 42px;
                    margin-top: 5px;
                }
            }
        }

        .login-input {
            width: 100%;
            height: 60px;
            border: #c4c4c4 1px solid;
            border-radius: 4px;
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            padding: 0 16px;

            .input-icon {
                height: 28px;
                width: 28px;
                margin-right: 8px;
            }

            input {
                flex: 1;
                height: 100%;
                border: none;
                outline: none;
                caret-color: #55b98a;

                &::placeholder {
                    color: #bebebe;
                    font-size: 16px;
                }
            }
        }

        .login-input-active {
            border: #55b98a 1px solid;
        }

        .login-button {
            width: 100%;
            background: #55b98a;
            color: #ffffff;
            height: 60px;
            border: none;
            font-size: 20px;
            border-radius: 4px;
            margin-top: 42px;
        }
    }

    .login-footer {
        color: #aaaaaa;
        font-size: 12px;
        text-align: center;
        line-height: 1;
        margin-top: 38px;

        p {
            margin-bottom: 4px;
        }
    }
}
</style>
